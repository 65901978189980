import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/views/Home.vue';
import Login from '@/views/Login.vue';
import Project from '@/views/Project.vue';
import Setting from '@/views/Setting.vue';
import Nopage from '@/views/Nopage.vue';
// ======================================================================
import itgsRouter from './itgs' // 物联网灯
import icpsRouter from './icps' // 供电包
import pahRouter from './pah' // 平安花
import slopeRouter from './slope' // 边坡
import brudgeRouter from './bridge' // 桥梁
import tunnelRouter from './tunnel' // 隧道
import melvesRouter from './melves' // 气象精灵
import geomRouter from './geom' // 地埋式车检器
import wlmsRouter from './wlms' // 水位精灵
import wgsRouter from './wgs' // 智慧护栏
import bucketRouter from './bucket' // 安全哨卫
import jcwRouter from './jcw' // 接触网
import camRouter from './720cam' // 围界监测
import fogRouter from './fog' // 团雾监测
import swjlRouter from './swjl' // 守望精灵
import rxroadRouter from './rxroad' // 荣县道路
import aiCameraRouter from './aicamera' // AI相机
// ======================================================================
Vue.use(VueRouter)

const routes = [
  // 首页
  { path: '/', name: 'Home',  component: Home,meta:{title:'新科汇智'}},
  { path: '/login', name: 'Login',  component: Login,meta:{title:'新科汇智'}},
  { path: '/project', name: 'Project',  component: Project,meta:{title:'新科汇智'}},
  { path: '/setting', name: 'Setting',  component: Setting,meta:{title:'设置中心'}},
  { path: '/nopage', name: 'Nopage',  component: Nopage,meta:{title:'敬请期待'}},
  ...itgsRouter, 
  ...icpsRouter, 
  ...pahRouter,
  ...slopeRouter,
  ...brudgeRouter,
  ...tunnelRouter,
  ...melvesRouter,
  ...geomRouter,
  ...wlmsRouter,
  ...wgsRouter,
  ...bucketRouter,
  ...jcwRouter,
  ...camRouter,
  ...fogRouter,
  ...swjlRouter,
  ...rxroadRouter,
  ...aiCameraRouter,
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router
