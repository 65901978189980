import { post , get , put} from '../request'

const aiCamera = {
     // 全部设备
     getAllDev:(data) => post('/aicamera/deviceBaseInfo/query',data),
     // 历史图片数据
     filePathPage:(pageNum,pageSize,data) => post(`/aicamera/imageFile/page?pageNum=${pageNum}&pageSize=${pageSize}`,data),
     //电压曲线
     batChartsData:(data) => post('/aicamera/exec/deviceStatus/echarts',data),
     //相机设备信息
     cameraInfo:(data) => post('/camera/deviceBaseInfo/query',data),
     //相机电压曲线
     cameraBatChartsData:(data) => post('/aicamera/exec/deviceStatus/echarts',data),
     // 历史图片数据
     cameraFilePathPage:(pageNum,pageSize,data) => post(`/camera/filePath/page?pageNum=${pageNum}&pageSize=${pageSize}`,data),

     alarmPage:(pageNum,pageSize,data) => post(`/aicamera/deviceAlarmInfo/page?pageNum=${pageNum}&pageSize=${pageSize}`,data),
}

export default aiCamera
