const homeIndex = () => import ('@/views/aicamera/Index.vue');
const list = () => import ('@/views/aicamera/dev/List.vue');
const detail = () => import ('@/views/aicamera/dev/Detail.vue');
const batLine = () => import ('@/views/aicamera/dev/BatLine.vue');

const image = () => import ('@/views/aicamera/image/Index.vue');

const warning = () => import ('@/views/aicamera/warning/Index.vue');

const about = () => import ('@/views/aicamera/about/Index.vue');

const aiCameraRouter = [
    { path: '/aicamera',component: homeIndex ,children:[
        { path: '/',name: 'List',meta:{title:'AI相机'},component: list},
        { path: 'detail',name: 'Detail',component: detail},
        { path: 'batline',name: 'BatLine',component: batLine},
        { path: 'image',name: 'BatLine',component: image},
        { path: 'warning',name: 'BatLine',component: warning},
        { path: 'about',name: 'BatLine',component: about},

    ]}
]

export default aiCameraRouter
