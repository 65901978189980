<template>
    <div class="project-content">
        <van-divider  :style="{color:'#2c3e50',borderColor:'#2c3e50',margin: '10px 0',fontWeight: 'bolder'}">项目列表</van-divider>
        <div class="list">
            <div v-if="loading">
                <van-loading type="spinner" color="#1989fa" style="line-height: 300px" />
            </div>
            <div v-else>
                <div v-if="projectList.length">
                    <div :class="{'row-0':true,'offline':item.deployFlag === 1}" v-for="(item,index) in projectList" :key="index" @click="goSystem(item)">
                        <div class="thumb">
                            <van-image
                                width="60"
                                height="60"
                                radius="10"
                                fit="cover"
                                :src="selectProjectImg(item.picUrl)"
                            />
                        </div>
                        <div class="info">
                            <div><span>项目名称：</span>{{item.projName}}{{item.projDetail}}</div>
                            <div><span>项目状态：</span>{{item.deployFlag === 1 ? '离线部署' : '线上部署'}}</div>
                            <div><span>更新时间：</span>{{item.updateTime || '暂无数据'}}</div>
                        </div>
                    </div>
                </div>
                <div v-else>
                    <van-empty description="暂无项目列表"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data(){
        return {
            loading:true,
            projectList:[],
            currentSystem:null
        }
    },
    methods:{
        // 返回项目图片
        selectProjectImg(img){
            let applyImg = null;
            switch(this.currentSystem){
                case 'itgs':
                    img ? applyImg = img : applyImg = require('@/assets/home/itgs.jpg')
                    break;
                case 'pah':
                    img ? applyImg = img : applyImg = require('@/assets/home/pah.jpg')
                    break;
                case 'slope':
                    img ? applyImg = img : applyImg = require('@/assets/home/slope.jpg')
                    break;
                case 'bridge':
                    img ? applyImg = img : applyImg = require('@/assets/home/bridge.jpg')
                    break;
                case 'icps':
                    img ? applyImg = img : applyImg = require('@/assets/home/icps.jpg')
                    break;
                case 'melves':
                    img ? applyImg = img : applyImg = require('@/assets/home/melves.jpg')
                    break;
                case 'geom':
                    img ? applyImg = img : applyImg = require('@/assets/home/geom.jpg')
                    break;
                case 'wlms':
                    img ? applyImg = img : applyImg = require('@/assets/home/wlms.jpg')
                    break;
                case 'wgs':
                    img ? applyImg = img : applyImg = require('@/assets/home/wgs.jpg')
                    break;
                case 'bucket':
                    img ? applyImg = img : applyImg = require('@/assets/home/bucket.jpg')
                    break;
                case 'tunnel':
                    img ? applyImg = img : applyImg = require('@/assets/home/tunnel.jpg')
                    break;
                case 'jcw':
                    img ? applyImg = img : applyImg = require('@/assets/home/jcw.jpg')
                    break;
                case '720cam':
                    img ? applyImg = img : applyImg = require('@/assets/home/720cam.jpg')
                    break;
                case 'fog':
                    img ? applyImg = img : applyImg = require('@/assets/home/fog.jpg')
                    break;
                case 'swjl':
                    img ? applyImg = img : applyImg = require('@/assets/home/minicam.jpg')
                    break;
                case 'aicamera':
                    img ? applyImg = img : applyImg = require('@/assets/home/aicamera.png')
                    break;
                default:
                    img ? applyImg = img : applyImg = require('@/assets/home/default.jpg');
            }
            return applyImg
        },
        // 获取列表
        getList(path){
            this.currentSystem = path;
            console.log(path);
            this.$api.SSO.getProjectList(path,{}).then( d => {
                this.loading = false
                this.projectList = d[path];
            }).catch( () => {
                this.loading = false
            })
        },
        // 跳转到各系统
        goSystem(item){

            // if(window.localStorage.getItem('visitor') == '1'){
            //     this.$toast('当前为游客模式，暂无权限');
            //     return false;
            // }

            if(item.deployFlag === 1){
                this.$toast('当前项目离线部署，无法访问');
                return false;
            }

            window.sessionStorage.setItem('projectCode',item.projCode);
            window.sessionStorage.setItem('projectName',`${item.projName}${item.projDetail}`);
            if((item.version && item.version.includes('M2')) && this.currentSystem === 'melves'){
                window.sessionStorage.setItem('melvesVesion','M2');
            }
            if((this.currentSystem === 'bridge' || this.currentSystem === 'slope') && !(item.version && item.version.includes('V2'))){
                this.$api.SLOPE.getProjectPage({
                        devType:this.currentSystem === 'bridge' ? 0 : 1,// 0是桥，1是边坡
                        projectCode:item.projCode
                    }).then( d => { 
                        if(d[0]){
                            window.sessionStorage.setItem('title',d[0].devName);
                            window.sessionStorage.setItem('bridgeCode',d[0].devCode);
                        }
                    });
            }

            // 新版本跳转判断
            if(item.appPath && item.appPath.trim().length > 0){
                //跳转指定的页面
                this.$router.push({path:item.appPath});
            }else{
                //默认跳转页面
                this.$router.push({path:this.$route.query.path});
            }

            //老版本跳转判断
            // if(this.currentSystem === 'bridge' || this.currentSystem === 'slope'){

            //     if(this.currentSystem === 'bridge' && item.version && item.version.includes('V2')){
            //         // 新版桥梁
            //         this.$router.push({path:'/bridge/newVersion/home'});
            //         window.sessionStorage.setItem('projectCode',item.projCode);
            //     } else if(this.currentSystem === 'slope' && item.version && item.version.includes('V2')){
            //         // 新版边坡
            //         this.$router.push({path:'/slope/newVersion/home'});
            //         window.sessionStorage.setItem('projectCode',item.projCode);
            //     }else{

            //         // 牛佛大桥更换新版桥梁接口
            //         if(this.currentSystem === 'bridge' && item.projCode === 'nfdq'){
            //             this.$router.push({path:'/bridge/niufo/home'});
            //             window.sessionStorage.setItem('projectCode',item.projCode);
            //             return false
            //         }
            //         // 牛佛大桥更换新版桥梁接口
            //         if(this.currentSystem === 'bridge' && item.projCode === 'nfeq'){
            //             this.$router.push({path:'/bridge/niufo/home'});
            //             window.sessionStorage.setItem('projectCode',item.projCode);
            //             return false
            //         }

            //         // 桥梁和边坡----直接跳转----业务代码
            //         window.sessionStorage.removeItem('onceAlarm');// 清空上一项目的告警数
            //         window.sessionStorage.setItem('magbot',1);// 赋值控制告警样式
            //         window.sessionStorage.setItem('projectCode',item.projCode);
            //         // 请求业务列表返回设备编号
            //         this.$api.SLOPE.getProjectPage({
            //             devType:this.currentSystem === 'bridge' ? 0 : 1,// 0是桥，1是边坡
            //             projectCode:item.projCode
            //         }).then( d => { 
            //             window.sessionStorage.setItem('title',d[0].devName);
            //             window.sessionStorage.setItem('bridgeCode',d[0].devCode);
            //             if(this.currentSystem === 'bridge'){
            //                 // 兼容旧版桥梁
            //                 // 前端分配进入桥梁主题页面
            //                 switch(item.projCode){
            //                     // case 'nfdq':
            //                     //     this.$router.push({path:'/bridge/niufo/home'});
            //                     //     break;
            //                     case 'dtskdq':
            //                         this.$router.push({path:'/bridge/lighthouse/home'});
            //                         break;
            //                     // case '110102001':
            //                     //     this.$router.push({path:'/bridge/bjLab/home'});
            //                     //     break;
            //                 }
            //             }else{
            //                 switch(item.projCode){
            //                     case 'zgyxbp':
            //                         this.$router.push({path:'/slope/rongxian/home'});
            //                         break;
            //                     case 'rxbysbp':
            //                         this.$router.push({path:'/slope/baiyanshi/home'});
            //                         break;
            //                     case 'rxbysbpx':
            //                         this.$router.push({path:'/slope/xinbaiyanshi/home'});
            //                         break;
            //                 }
            //             }
            //         })
            //     }
            // }
            // else{
            //     // 其他平台
            //     if((item.version && item.version.includes('M2')) && this.currentSystem === 'melves'){
            //         window.sessionStorage.setItem('melvesVesion','M2');
            //     }

            //     window.sessionStorage.setItem('projectCode',item.projCode);
            //     // 水位精灵存一下项目名称
            //     window.sessionStorage.setItem('projectName',`${item.projName}${item.projDetail}`);

            //     //项目定制
            //     switch(item.projCode){
            //         case 'scrxdlqxyj':
            //             this.$router.push({path:'/rxroad/'});
            //             break;
            //         default:
            //             this.$router.push({path:this.$route.query.path});
            //             break;

            //     }

            // }
        }
    },
    mounted(){
        window.sessionStorage.removeItem('melvesVesion');
        console.log(this.$route.query.path)
        this.$nextTick(()=>{
            
            this.getList(this.$route.query.path)
        })
    }
}
</script>

<style lang="scss" scoped>
.project-content{
    // background-image: linear-gradient(141deg,#affbda 0%,#1fc8db 51%,#2cb5e8 75%);
    // background: linear-gradient(141deg, #33bccc 51%, #4196bf 75%);
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 0 20px 20px 20px;
    overflow-y: auto;
    .list{
        max-height: 90vh;
        overflow-y: auto;
        font-size: .8rem;
        .row-0{
            background: #276ba0;
            padding: 12px;
            box-shadow: 2px 2px 3px #b7b7b7;
            border-radius: 8px;
            color: #fff;
            margin-bottom: 10px;
            display: flex;
            align-items: center;
            .thumb{
                width: 22%;
            }
            .info{
                width: 76%;
                padding-left: 2%;
                text-align: left;
                div{
                    margin-bottom: 5px;
                    span{
                        font-weight: lighter;
                        color: #f3f3f3;
                    }
                }
            }
            
        }
        .offline{
             background: #797979c4;
            padding: 12px;
            box-shadow: 2px 2px 3px #b7b7b7;
            border-radius: 8px;
            color: #fff;
            margin-bottom: 10px;
        }
    }
}
</style>