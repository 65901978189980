import axios from 'axios'
import { Toast } from 'vant';
import { get,post } from './request.js'
import Vue from 'vue'
import { appId, redirectUrl } from '@/config/weixin.js'

// 物联网灯接口
import itgs from './itgs/index';
// 供电包接口
import icps from './icps/index';
// 平安花接口
import pah from './pah/index';
// 边坡接口
import slope from './slope/index';
// 桥梁接口
import bridge from './bridge/index';
// 气象精灵
import melves from './melves/index';
// 地理车检器
import geom from './geom/index';
// 水位精灵
import wlms from './wlms/index';
// 智慧护栏
import wgs from './wgs/index';
// 安全哨卫
import bucket from './bucket/index';
// 新版桥梁
import newBridge from './bridge/newIndex';
// 接触网
import jcw from './jcw/index';
// 围界监测
import camera from './720cam/index';
// 团雾监测
import fog from './fog/index';

import swjl from './swjl/index';

import rxroad from './rxroad/index';

import aiCamera from './aicamera/index';

axios.defaults.withCredentials=true;
axios.defaults.headers.post['Content-Type'] = 'application/json;charset=UTF-8';//配置请求头
Vue.use(Toast);

//请求拦截器
axios.interceptors.request.use(config => {
    // const token = 'DYji86sSnkwnnllK927gHqBG2mMeQcmPYTs9UBbLmRKlMDxLTTIg15J7GwqOfTwp'; // 测试
    // const token = '4w6m0HUviAzQ0IdAktNLH80LEBevhDEAH9YJKh82F2QaMcwWBEsEEZDtrJYnvBoB'; // 正式
    const token = window.localStorage.getItem("Tit-token");
    token && (config.headers['Tit-token'] = token);
    return config;
}, err => {
    Toast.fail('请求异常');
    console.log('请求大错误：',err);
});

//响应拦截器
axios.interceptors.response.use((res) => {
    if (res.data.code === 200) {
        return res.data || res.rows
    } else if(res.data.code === '0') {
        // 兼容视频转码数据格式
        return res.data
    }else if(res.data.code === 401) {
        Toast.fail(res.data.msg);
        localStorage.removeItem('Tit-token');
        // 用户权限失效，重新获取token
        window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appId}&redirect_uri=${redirectUrl}&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect`;
        return false;
    } else {
        Toast.fail(res.data.msg);
        return Promise.reject(res);
    }
}, (err) => {
    Toast.fail('响应异常');
    console.log('响应大错误：',err);
});


axios.API={
    SSO:{
        // 是否登录
        isLogin:(data) => get('/login/getLoginInfo',data),
        // 根据openid判断是否绑定
        isBindingByOpenId:(data) => post('/login/checkUser',data),
        // 通过ticket登录
        doLoginByTicket:(data) => post('/login/doWxLoginByTicket',data),
        // 退出登录
        exitUser:(data) => post('/sso/logout',data),
        // 游客模式
        doWxLoginByOpenId:(data) => post('/login/doWxTouristLoginByOpenId',data),
        // 获取sever端地址
        goSsoAuthUrl:(data) => post('/login/getSsoAuthUrl',data),
        // 获取项目列表
        getProjectList:(path,data) => post('/main/'+path+'/initProjects',data),
        // openid获取用户头像昵称信息
        openIdUserBaseInfo:(id) => get('/wechat/getUserInfo/'+id),
        // 回显消息模板项目
        echoTempMsgPro:(id) => get('/auth/sysMsgConfig/info/'+id),
        // 提交消息模板项目配置
        saveTempMsgPor:(data) => post('/auth/sysMsgConfig/save',data),
        // 权限
        getPermission:(data) => post('/auth/menu/getPermission',data),
    },
    ITGS:{ ...itgs },
    ICPS:{ ...icps },
    PAH:{ ...pah },
    SLOPE:{ ...slope },
    BRIDGE:{ ...bridge },
    MELVES:{...melves },
    GEOM:{...geom },
    WLMS:{...wlms },
    WGS:{...wgs },
    BUCKET:{...bucket },
    NEWBRIDGE:{ ...newBridge },
    JCW:{ ...jcw },
    CAMERA:{ ...camera },
    FOG:{ ...fog },
    SWJL:{ ...swjl },
    RXROAD:{...rxroad},
    AICAMERA:{...aiCamera},
}

export default axios.API;